import Axios from "axios";
import store from "../store";

import { EnvironmentConstants } from "../environment";
import { parseISO, subHours, format, startOfMonth } from "date-fns";
import { DownloadFile } from "../utilities";

const APP_SOURCE = "GMPCorpSite";
const CLIENT_ID = "C978562571FC475294191C7B94DD883E";

const EXPERIAN_URL = "https://api.experianaperture.io";
const EXPERIAN_TOKEN = "8354e97c-e353-448f-ab52-6b31f83f1cce"; // Use this token when using Experian API from QA or production www instances
//const EXPERIAN_TOKEN = "2b8235f3-d89f-4171-ad51-8582748fce90";  // Uncomment and use this token when using Experian API from local wwww instance

const WEBCHAT_URL = "https://chat.greenmountainpower.com:8085/webchat/client";

const SOURCE_HEADER = {
  "GMP-Source": "web",
};

/** Will not execute updates in non-prod environments for APIS that support this parameter.
 * Should always be commented out for commit, only uncommented for local development */
const DRY_RUN_HEADER = {
  // "GMP-DryRun": "true"
}

export default class GMPAPI {
  /** Make an unauthed call to the GMP API */
  static async GMPCallAnonymous(method, route, data, isStandAloneApi) {
    let url = !isStandAloneApi ? EnvironmentConstants.GmpApiURL + route : EnvironmentConstants.GmpStandAloneApiURL + route;
    if (route.startsWith("/payments")) {
      url = EnvironmentConstants.GmpPayApiURL + route;
    }

    const response = await Axios({
      method,
      url,
      data,
      headers: SOURCE_HEADER,
    });
    return response.data;
  }

  /** Make an unauthed call to the GMP API running on localhost*/
  static async LocalGMPCallAnonymous(method, route, data) {
    let url = EnvironmentConstants.LocalGmpAPIURL + route;
    if (route.startsWith("/payments")) {
      url = EnvironmentConstants.GmpPayApiURL + route;
    }

    const response = await Axios({
      method,
      url,
      data,
      headers: SOURCE_HEADER,
    });
    return response.data;
  }


  /** Make a call to the GMP API with the user auth token */
  static async GMPCallAuthed(method, route, data, extraOptions = {}) {
    let headers = SOURCE_HEADER;
    const token = store.state.user.apitoken;
    if (token) {
      headers = {
        ...DRY_RUN_HEADER,
        ...SOURCE_HEADER,
        Authorization: "Bearer " + token,
      };
    }

    let url = EnvironmentConstants.GmpApiURL + route;
    if (route.startsWith("/payments")) {
      url = EnvironmentConstants.GmpPayApiURL + route;
    }

    const response = await Axios({
      method,
      url,
      data,
      headers,
      withCredentials: true,
      ...extraOptions,
    });
    return response.data;
  }

  /** Some GMP API calls return 404s instead of empty arrays etc when there are no records. This fixes that. */
  static async GMPCallAuthedWith404Subst(method, route, data) {
    try {
      const response = await this.GMPCallAuthed(method, route, data);
      return response;
    } catch (err) {
      if (err.response && err.response.status === 404) {
        return [];
      } else {
        throw err;
      }
    }
  }

  /** Some GMP API calls return 404s instead of empty arrays etc when there are no records. This fixes that. */
  static async GMPCallAnonymousWith404Subst(method, route, data, isStandAloneApi) {
    try {
      const response = await this.GMPCallAnonymous(method, route, data, isStandAloneApi);
      return response;
    } catch (err) {
      if (err.response && err.response.status === 404) {
        return [];
      } else {
        throw err;
      }
    }
  }

  static async GetTokenUsername(username, password, rememberme) {
    const params = new URLSearchParams();
    params.append("username", username);
    params.append("password", password);
    params.append("client_id", CLIENT_ID);
    const response = await Axios.post(EnvironmentConstants.GmpApiURL + "/applications/token?remember_me=" + rememberme, params, { headers: SOURCE_HEADER, withCredentials: true });
    return response.data;
  }

  static async GetRefreshedToken(refreshToken, rememberme) {
    const params = new URLSearchParams();
    params.append("grant_type", "refresh_token");
    params.append("refresh_token", refreshToken);
    params.append("client_id", CLIENT_ID);
    const response = await Axios.post(EnvironmentConstants.GmpApiURL + "/applications/token?remember_me=" + rememberme, params, { headers: SOURCE_HEADER, withCredentials: true });
    return response.data;
  }

  static async RevokeRefreshToken(refreshToken) {
    try {
      const params = new URLSearchParams();
      params.append("refresh_token", refreshToken);
      params.append("client_id", CLIENT_ID);
      const response = await Axios.post(EnvironmentConstants.GmpApiURL + "/applications/token/revoke", params, { headers: SOURCE_HEADER, withCredentials: true });
    } catch (err) {
      // Don't worry about it
    }
  }

  static async GetTokenOTP(accountNumber, phoneLast) {
    const params = new URLSearchParams();
    params.append("grant_type", "account_phone");
    params.append("account_number", accountNumber);
    params.append("phone_number", phoneLast);
    params.append("client_id", CLIENT_ID);
    const response = await Axios.post(EnvironmentConstants.GmpApiURL + "/applications/token?remember_me=false", params, { headers: SOURCE_HEADER });
    return response.data;
  }

  static async GetTokenPhone(phoneNumber) {
    const params = new URLSearchParams();
    params.append("grant_type", "phone_number");
    params.append("phone_number", phoneNumber);
    // TODO: this causes failure
    // params.append("client_id", CLIENT_ID);
    const response = await Axios.post(EnvironmentConstants.GmpApiURL + "/applications/token?remember_me=false", params, { headers: SOURCE_HEADER });
    return response.data;
  }

  static async LogoutCookie() {
    try {
      await this.GMPCallAuthed("get", "/applications/logout");
    } catch (err) {
      // Don't worry about it
    }
  }

  // Not logged in
  static async VerifyAccountPhone(accountNumber, phoneLast) {
    await this.GMPCallAnonymous("put", "/accounts/" + accountNumber + "/verify", { accountNumber, value: phoneLast, type: "phone" });
  }
  static async GetPowerwallInstallers() {
    return await this.GMPCallAnonymous("get", "/css/installers?type=powerwall");
  }
  static async GetIncidentsSummary() {
    return await this.GMPCallAnonymous("get", "/outages/incidents/summary", {}, true);
  }
  static async GetOutageForecast() {
    return await this.GMPCallAnonymous("get", "/outages/forecast");
  }
  static async GetPaymentMeta() {
    return await this.GMPCallAnonymous("get", "/payments/meta");
  }
  // This one should always be called through dispatch "CreateUser" so it can store the results in vuex
  static async CreateUser(body) {
    return await this.GMPCallAnonymous("post", "/users", body);
  }

  // Note: the next 3 calls should probably be made via GMPCallAnonymous, but have already gone through testing
  static async ResetPassword(email) {
    return await this.GMPCallAuthed("post", "/users/" + encodeURIComponent(email) + "/password/reset");
  }
  static async UpdateResetPassword(token, password) {
    return await this.GMPCallAuthed("post", "/users/password/update", { token, newPassword: password });
  }
  static async ForgotUsername(email) {
    return await this.GMPCallAuthed("post", "/users/" + encodeURIComponent(email) + "/forgot-username");
  }
  static async GetEbillToken(token) {
    return await this.GMPCallAnonymous("get", "/users/ebill/contacts?token=" + encodeURIComponent(token));
  }
  static async SubmitEbillToken(token, accounts) {
    return await this.GMPCallAnonymous("post", "/accounts/ebill/enroll", { token, accounts });
  }
  static async VerifyContact(token) {
    await this.GMPCallAnonymous("post", "/alerts/contacts/verify?token=" + token);
  }
  static async CheckMagicLink(token) {
    return await this.GMPCallAnonymous("get", "/users/email?token=" + token);
  }

  // User info
  static async GetCurrentUser() {
    const current = await this.GMPCallAuthed("get", "/users/current");
    if (current && current.groups && current.groups.length) {
      const admin = current.groups.find(item => item.name === "CSS_ADMIN");
      const veic = current.groups.find(item => item.name === "VEIC_USER");
      if (admin) {
        current.isAdmin = true;
      }
      if (veic) {
        current.isAdminLite = true;
      }
    }
    if (current.username.startsWith("TEMP_ACCOUNT_")) {
      // Happens with an account # or phone # login
      current.isOTP = true;
    }
    return current;
  }
  static async GetAccounts(accountNumbers) {
    const numberList = accountNumbers.join(",");
    return await this.GMPCallAuthed("get", "/accounts/list?accounts=" + numberList);
  }

  // ev charger
  static async GetEvEnergy(accountNumber, startDate, endDate) {
    return await this.GMPCallAuthed("get", "/device/account/" + accountNumber + "/ev/energy/daily?startDate=" + startDate + "&endDate=" + endDate);
  }

  static async GetEvEnergyByDevice(accountNumber, deviceId, startDate, endDate) {
    return await this.GMPCallAuthed("get", "/device/account/" + accountNumber + "/ev/energy/daily?startDate=" + startDate + "&endDate=" + endDate+ "&deviceIds=" + deviceId);
  }

  static async getEvSettings(accountNumber) {
    return await this.GMPCallAuthed("get", "/device/account/" + accountNumber + "/ev/settings");
  }

  static async updateEvSettings(accountNumber, evSettings) {
    return await this.GMPCallAuthed("post", "/device/account/" + accountNumber + "/ev/settings", evSettings);
  }

  static async deleteEvSettings(accountNumber) {
    return await this.GMPCallAuthed("delete", "/device/account/" + accountNumber + "/ev/settings");
  }

  // Payment
  static async GetWallets(accountNumber, checkRecurringSchedule=true) {
    let wallets = [];
    let walletsCache = store.getters.getWallets(accountNumber);
    let fromCache = false;

    if (walletsCache &&
      walletsCache.checkRecurringSchedule &&
      walletsCache.checkRecurringSchedule == checkRecurringSchedule) {
        walletsCache.wallets.forEach(wallet => {
          wallets.push(JSON.parse(JSON.stringify(wallet)));
        });
        fromCache = true;

    } else {
      wallets = await this.GMPCallAuthedWith404Subst("get",
      "/payments/account/" + accountNumber + "/wallet?checkRecurringSchedule=" + checkRecurringSchedule);
    }

    const expiryCutoff = startOfMonth(new Date());
    for (const wallet of wallets) {
      if (wallet.expirationDate && wallet.expirationDate.length >= 8) {
        try {
          const parsed = parseISO(wallet.expirationDate);
          // Check for expired cards and reformat expiration date
          if (parsed < expiryCutoff) {
            wallet.isExpired = true;
          }
          wallet.expirationDate = format(parsed, "MM/yy");
        } catch (err) {
          console.error("Invalid expiration date: " + wallet.expirationDate);
        }
      }
    }

    if (!fromCache) {
      const payload = {
        accountNumber: accountNumber,
        wallets: wallets,
        checkRecurringSchedule: checkRecurringSchedule
      }
      store.commit("setWallets", payload);
    }

    return wallets;
  }


  /** Returns the id of the created wallet */
  static async CreateWallet(accountNumber, walletRecord) {
    const body = {
      source: APP_SOURCE,
      walletRecord,
    };
    const result = await this.GMPCallAuthed("post", "/payments/account/" + accountNumber + "/wallet", body);
    store.commit("clearWallets", accountNumber);
    store.commit("clearRecurringStatus", accountNumber);
    return result.walletId;
  }
  static async UpdateWallet(accountNumber, walletRecord, walletId) {
    const body = {
      source: APP_SOURCE,
      walletRecord,
    };
    store.commit("clearWallets", accountNumber);
    store.commit("clearRecurringStatus", accountNumber);
    return await this.GMPCallAuthed("put", "/payments/account/" + accountNumber + "/wallet/" + walletId, body);
  }
  static async DeleteWallet(accountNumber, walletId) {
    store.commit("clearWallets", accountNumber);
    store.commit("clearRecurringStatus", accountNumber);
    return await this.GMPCallAuthed("delete", "/payments/account/" + accountNumber + "/wallet/" + walletId + "?source=" + APP_SOURCE + "&checkRecurringSchedule=true");
  }
  static async GetScheduleAllowed(accountNumber) {
    return await this.GMPCallAuthed("get", "/payments/" + accountNumber + "/scheduled/permitted", undefined, { timeout: 6000 });
  }
  static async MakePayment(accountNumber, paymentInsertRecord) {
    store.commit("clearPaymentHistory", accountNumber);
    const body = {
      source: APP_SOURCE,
      paymentInsertRecord,
    };
    return await this.GMPCallAuthed("post", "/payments/" + accountNumber + "/payment", body);
  }
  static async DeleteScheduledPayment(accountNumber, paymentId) {
    store.commit("clearPaymentHistory", accountNumber);
    return await this.GMPCallAuthed("delete", "/payments/" + accountNumber + "/payment/" + paymentId + "?source=" + APP_SOURCE);
  }
  static async GetRecurring(accountNumber) {
    const recurringStatusCache = store.getters.getRecurringStatus(accountNumber);
    if (recurringStatusCache) { return recurringStatusCache; }
    const recurringStatus =  await this.GMPCallAuthedWith404Subst("get", "/payments/account/" + accountNumber + "/recurring");

    const payload = {
      accountNumber: accountNumber,
      recurringStatus: recurringStatus
    }
    store.commit("setRecurringStatus", payload);

    return recurringStatus;
  }
  static async SetRecurring(accountNumber, walletId, emailAddress, customerInformation, debitInformation) {
    store.commit("clearWallets", accountNumber);
    store.commit("clearRecurringStatus", accountNumber);
    const body = {
      source: APP_SOURCE,
      walletId,
      insertRecord: {
        customerInformation,
        recurringScheduleInformation: {
          emailAddress,
        },
        debitInformation,
      },
    };
    return await this.GMPCallAuthed("post", "/payments/account/" + accountNumber + "/recurring", body);
  }
  static async DeleteRecurring(accountNumber, recurringScheduleId) {
    store.commit("clearWallets", accountNumber);
    store.commit("clearRecurringStatus", accountNumber);
    return await this.GMPCallAuthed("delete", "/payments/account/" + accountNumber + "/recurring/" + recurringScheduleId + "?source=" + APP_SOURCE);
  }

  // devices
  static async GetDevices(accountNumber) {
    const result = await this.GMPCallAuthed("get", "/device/account/" + accountNumber + "/devices");
    return result;
  }

  static async GetImpact(accountNumber, queryString) {
    const result = await this.GMPCallAuthed("get", "/device/account/" + accountNumber + "/devices/impact" + queryString);
    return result;
  }

  static async GetDeviceDetail(accountNumber, deviceId, queryString) {
    const result = await this.GMPCallAuthed("get", "/device/account/" + accountNumber + "/devices/" + deviceId + queryString);
    return result;
  }

  static async GetDeviceInfo(accountNumber, deviceId) {
    const result = await this.GMPCallAuthed("get", "/device/account/" + accountNumber + "/devices/" + deviceId + "/device-info");
    return result;
  }


  static async GetBatteryChargeData(accountNumber, deviceId, interval, startDate, endDate) {
    const result = await this.GMPCallAuthed("get", "/device/account/" + accountNumber + "/battery/" + deviceId + "/energy/" + interval + "?startDate=" + startDate + "&endDate=" + endDate);
    return result;
  }

  static async GetDevicesStatusHistory(accountNumber, startDate, endDate) {
    const result = await this.GMPCallAuthed("get", "/device/account/" + accountNumber + "/devices/history?startDate=" + startDate + "&endDate=" + endDate);
    return result;
  }

  static async UpdateDeviceEventEnrollment(accountNumber, deviceID, eventId, data) {
    const result = await this.GMPCallAuthed("post", "/device/account/" + accountNumber + "/devices/" + deviceID + "/events/" + eventId, data);
    return result;
  }

  // Usage
  static async GetPeriodUsage(accountNumber, interval, startDate, endDate) {
    //For hourly usage, on the DST spring ahead day the request to the API fails because endDate ends up with wrong offset.
    if (interval === "hourly" && startDate.substring(0, 10) === endDate.substring(0, 10)) {
      endDate = startDate;
    }

    const result = await this.GMPCallAuthed("get", "/usage/" + accountNumber + "/" + interval + "?startDate=" + startDate + "&endDate=" + endDate + "&temp=f");
    if (result && result.intervals && result.intervals.length && result.intervals[0].values && result.intervals[0].values.length) {
      for (const datapoint of result.intervals[0].values) {
        datapoint.date = this.FixUsageDate(datapoint.date, interval);
      }
    }
    return result;
  }

  /** Usage API returns incorrectly formatted / offset dates */
  static FixUsageDate(date, interval) {
    // The usage API always returns "UTC" but the actual times are VT local
    let parsed = parseISO(date.slice(0, -1));
    if (interval === "hourly") {
      parsed = subHours(parsed, 1);
    }
    return parsed;
  }

  static async DownloadUsageExport(accountNumber, filename, startDate, endDate, format) {
    const contents = await this.GMPCallAuthed("get", "/usage/" + accountNumber + "/download?startDate=" + startDate + "&endDate=" + endDate + "&format=" + format);
    DownloadFile(filename, contents);
  }

  static async GetSuggestedUsageThresholds(accountNumber) {
    return await this.GMPCallAuthedWith404Subst("get", "/usage/" + accountNumber + "/threshold/suggested");
  }

  static async GetAccountUsageThresholds(accountNumber) {
    return await this.GMPCallAuthedWith404Subst("get", "/usage/" + accountNumber + "/threshold");
  }

  static async CreateUsageThresholds(accountNumber, usageThreshold) {
    return await this.GMPCallAuthed("post", "/usage/" + accountNumber + "/threshold", usageThreshold);
  }

  static async UpdateUsageThresholds(accountNumber, id, usageThreshold) {
    return await this.GMPCallAuthed("put", "/usage/" + accountNumber + "/threshold/" + id, usageThreshold);
  }

  static async DeleteUsageThresholds(accountNumber, id)  {
    return await this.GMPCallAuthed("delete", "/usage/" + accountNumber + "/threshold/" + id);
  }

  static async GetUsageSummary(accountNumber) {
    const result = await this.GMPCallAuthed("get", "/usage/" + accountNumber + "/" + "summary");
    return result;
  }

  static async GetAccountUsageSnapshot(accountNumber, startDate, endDate, expand) {
    return await this.GMPCallAuthedWith404Subst("get", "/usage/" + accountNumber + "/snapshot?startDate=" + startDate + "&endDate=" + endDate + (expand ? "&expand=" + expand : ''));
  }

  // Billing
  // Get bill details
  static async getBillDetails(accountNumber, billId) {
    return await this.GMPCallAuthed("get", "/accounts/" + accountNumber + "/bills/" + billId + "/details");
  }

  // Get flat line-items
  static async GetBillingRates(accountNumber, startDate, endDate) {
    return await this.GMPCallAuthed("get", "/accounts/" + accountNumber + "/bills/line-items/rates/?startDate=" + startDate + "&endDate=" + endDate);
  }

  // View
  static async GetTransposedBillingReport(accountNumber, data) {
    return await this.GMPCallAuthed("post", "/accounts/" + accountNumber + "/bills/line-items?transpose=true", data);
  }

  // Download
  static async DownloadBillingReport(accountNumber, data) {
    const contents = await this.GMPCallAuthed("post", "/accounts/" + accountNumber + "/bills/line-items?download=true", data);
    return contents
  }

  static async GetBillingPeriods(accountNumber) {
    const billingPeriodsCache = store.getters.getBillingPeriods(accountNumber);
    if (billingPeriodsCache) { return billingPeriodsCache };
    const billingPeriods = await this.GMPCallAuthed("get", "/accounts/" + accountNumber + "/billing/periods");
    const payload = {
      accountNumber: accountNumber,
      billingPeriods: billingPeriods
    }
    store.commit("setBillingPeriods", payload);
    return billingPeriods;
  }
  static async GetAccountTransactions(accountNumber, startDate, endDate) {
    const transactions = await this.GMPCallAuthedWith404Subst("get", "/accounts/" + accountNumber + "/transactions?startDate=" + startDate + "&endDate=" + endDate);
    // Convert to JS dates
    for (const transaction of transactions) {
      transaction.date = parseISO(transaction.date);
    }
    return transactions;
  }
  /** Note: ignore speedpayAutoPayEnrolled, it is not accurate. Instead call GetRecurring */
  static async GetBillingStatus(accountNumber) {
    const billingStatusCache = store.getters.getBillingStatus(accountNumber);

    if (billingStatusCache) { return billingStatusCache };

    const billingStatus = await this.GMPCallAuthed("get", "/accounts/" + accountNumber + "/billing");

    const payload = {
      accountNumber: accountNumber,
      billingStatus: billingStatus
    }

    store.commit("setBillingStatus", payload);
    return billingStatus;
  }
  static async UpdateBillingStatus(accountNumber, props) {
    store.commit("clearBillingStatus", accountNumber);
    return await this.GMPCallAuthed("put", "/accounts/" + accountNumber + "/billing?fields=billRouteInformation", props);
  }
  static async GetBudgetStatus(accountNumber) {
    return await this.GMPCallAuthed("get", "/accounts/" + accountNumber + "/billing/budget");
  }
  static async CreateBudgetBilling(accountNumber) {
    return await this.GMPCallAuthed("post", "/accounts/" + accountNumber + "/billing/budget", {});
  }
  static async GetBillingHistory(accountNumber, daysBackToSearch) {
    const paymentHistoryCache = store.getters.getPaymentHistory(accountNumber, daysBackToSearch);
    if (paymentHistoryCache) { return paymentHistoryCache; }

    let query = "";
    if (daysBackToSearch) {
      query = "?daysBackToSearch=" + daysBackToSearch;
    }

    const paymentHistory = await this.GMPCallAuthedWith404Subst("get", "/payments/" + accountNumber + "/history" + query);
    const payload = {
      accountNumber: accountNumber,
      paymentHistory: paymentHistory,
      daysBackToSearch: daysBackToSearch
    }

    store.commit("setPaymentHistory", payload);
    return paymentHistory;
  }

  static async GetSpeedpayCreds(accountNumber, input) {
    return await this.GMPCallAuthed("get", "/payments/" + accountNumber + "/sso?input=" + encodeURIComponent(input));
  }

  // Product Service

  static async getElectricalSystemUpgradeReasons() {
    return await this.GMPCallAuthed("get", "/products/electrical-system-upgrade-reasons/values");
  }

  static async getElectricalSystemUpgradeProjectScope() {
    return await this.GMPCallAuthed("get", "/products/electrical-system-upgrade-project-scope/values");
  }

  static async GetEVDealerships() {
    return await this.GMPCallAuthed("get", "/products/electric-vehicle-dealers/values");
  }

  static async GetHeatPumpManufacturers() {
    return await this.GMPCallAuthed("get", "/products/heat-pump-manufacturers/values");
  }

  static async GetHeatPumpModels(manufacturer) {
    return await this.GMPCallAuthed("get", "/products/heat-pump-models/values" + "?manufacturer="+manufacturer);
  }


  static async GetEICMeta(productType, effectiveDate, countyName) {
    const queryParams = {};
    if (productType) {
      queryParams.productType = productType;
    }
    if (effectiveDate) {
      queryParams.effectiveDate = effectiveDate;
    }
    if (countyName) {
      queryParams.countyName = countyName;
    }
  
    const queryString = new URLSearchParams(queryParams).toString();
    const url = queryString ? `/products/meta?${queryString}` : "/products/meta";
    return await this.GMPCallAuthed("get", url);
  }

  static async GetEVModels() {
    return await this.GMPCallAuthed("get", "/products/electric-vehicle-models/values");
  }

  static async GetSalesforceAccounts(accountType) {
    return await this.GMPCallAuthed("get", "/products/accounts?accountType=" + accountType);
  }

  static async GetCounty(townName) {
    return await this.GMPCallAuthed("get", "/products/county?townName=" + townName);
  }

  static async GetYardCareProducts() {
    return await this.GMPCallAuthed("get", "/products/yard-care-products/values");
  }

  static async SubmitElectricPanelGrant(accountNumber, data) {
    return await this.GMPCallAuthed("post", "/products/account/" + accountNumber + "/electric-panel", data);
  }

  static async SubmitEVRebte(accountNumber, data) {
    return await this.GMPCallAuthed("post", "/products/account/" + accountNumber + "/electric-vehicle", data);
  }

  static async SubmitEVCharger(accountNumber, data) {
    return await this.GMPCallAuthed("post", "/products/account/" + accountNumber + "/electric-vehicle-charger", data);
  }

  static async SubmitEBikeRebate(accountNumber, data) {
    return await this.GMPCallAuthed("post", "/products/account/" + accountNumber + "/electric-bike", data);
  }

  static async SubmitHeatPumpIncomeRebate(accountNumber, data) {
    return await this.GMPCallAuthed("post", "/products/account/" + accountNumber + "/heat-pump", data);
  }

  static async SubmitInductionCooktopIncentive(accountNumber, data) {
    return await this.GMPCallAuthed("post", "/products/account/" + accountNumber + "/induction-stove", data);
  }

  static async SubmitYardCareRebate(accountNumber, data) {
    return await this.GMPCallAuthed("post", "/products/account/" + accountNumber + "/yard-care", data);
  }

  // Alerts
  static async GetAlertContacts(accountNumber) {
    return await this.GMPCallAuthedWith404Subst(" ", "/alerts/accounts/" + accountNumber + "/contacts?expandAll=true");
  }
  static async GetAlertTypes(accountNumber) {
    const result = await this.GMPCallAuthedWith404Subst("get", "/alerts/accounts/" + accountNumber + "/alert-types");
    if (result && result.length === 0) return [];
    return result.alertTypes;
  }
  static async GetAlertChannels() {
    return await this.GMPCallAuthed("get", "/alerts/channels");
  }
  static async SubscribeAlert(accountNumber, contactId, alertTypeId) {
    return await this.GMPCallAuthed("post", "/alerts/accounts/" + accountNumber + "/subscriptions", { contact: { contactId }, alertType: { alertTypeId } });
  }
  static async UnSubscribeAlert(accountNumber, subscriptionId) {
    return await this.GMPCallAuthed("delete", "/alerts/accounts/" + accountNumber + "/subscriptions/" + subscriptionId);
  }
  static async CreateContact(accountNumber, body) {
    return await this.GMPCallAuthed("post", "/alerts/accounts/" + accountNumber + "/contacts", body);
  }
  static async UpdateContact(accountNumber, contactId, body) {
    return await this.GMPCallAuthed("put", "/alerts/accounts/" + accountNumber + "/contacts/" + contactId, body);
  }
  static async DeleteContact(accountNumber, contactId) {
    return await this.GMPCallAuthed("delete", "/alerts/accounts/" + accountNumber + "/contacts/" + contactId);
  }
  static async ResendContactVerification(accountNumber, contactId) {
    return await this.GMPCallAuthed("post", "/alerts/accounts/" + accountNumber + "/contacts/" + contactId + "/resend", {});
  }

  // Outages
  static async GetAccountIncidents(accountNumber) {
    return await this.GMPCallAuthedWith404Subst("get", "/outages/incidents/account/" + accountNumber);
  }
  static async GetReportedIncident(accountNumber) {
    return await this.GMPCallAuthed("get", "/outages/accounts/" + accountNumber + "/reported");
  }
  static async GetOneIncident(incidentId) {
    return await this.GMPCallAuthed("get", "/outages/incidents/" + incidentId);
  }
  static async GetAllIncidents() {
    const response = await this.GMPCallAnonymousWith404Subst("get", "/outages/incidents?active=true", {}, true);
    const sorted = response.sort((a, b) => {
      if (a.createdAt < b.createdAt) {
        return -1;
      } else if (a.createdAt > b.createdAt) {
        return 1;
      } else {
        return 0;
      }
    });
    const grouped = {};
    // Group incidents by town, then by street. Temporary so we can set counters.
    for (const incident of sorted) {
      if (!grouped[incident.town]) {
        grouped[incident.town] = {};
      }
      if (!grouped[incident.town][incident.street]) {
        grouped[incident.town][incident.street] = [];
      }
      grouped[incident.town][incident.street].push(incident);
    }
    // For any streets with multiple incidents, set their "counter" to "(# of #)"
    for (const townKey of Object.keys(grouped)) {
      for (const streetKey of Object.keys(grouped[townKey])) {
        const streetGroup = grouped[townKey][streetKey];
        if (streetGroup.length > 1) {
          for (let i = 0; i < streetGroup.length; i++) {
            streetGroup[i].counter = "(" + (i + 1) + " of " + (streetGroup.length) + ")";
          }
        }
      }
    }
    return sorted;
  }
  static async GetMessagesByIncidentId(incidentId) {
    return await this.GMPCallAuthed("get", "/outages/incidents/" + incidentId + "/messages");
  }
  static async GetMessageByMessageId(messageId, preview) {
    let url = "/outages/messaging/messages/" + messageId;
    if (preview) {
      url += '?preview=true';
    }
    return await this.GMPCallAuthedWith404Subst("get", url);
  }
  static async GetTownData() {
    const result = await this.GMPCallAnonymous("get", "/outages/incidents/towns?all=true", {}, true);
    // Remove towns with no GMP customers. Note that we may want to do this filtering on the API side to cut down on bandwidth
    const filtered = result.filter(item => {
      return item.total_customers > 0;
    });
    return filtered;
  }
  static async GetVermontTowns() {
    const result = await this.GMPCallAnonymous("get", "/css/locations/vermont/towns");
    return result;
  }
  static async GetActiveIncidentShapes() {
    try {
      return await this.GMPCallAnonymous("get", "/outages/incidents/boundaries?active=true&format=geojson&groupBy=town", {}, true);    } catch (e) {
      console.error("Failed to get active incident shapes");
      console.log(e);
      return {
        type: "FeatureCollection",
        features: [],
      };
    }
  }

  static async GetActiveIncidentPoints() {
    try {
      const response = await this.GMPCallAnonymous("get", "/outages/incidents?active=true&format=geojson", { "type": "FeatureCollection", "features": [] }, true);
      const sorted = response.features.sort((a, b) => b.properties.age - a.properties.age);
      const grouped = {};
      // Group incidents by town, then by street
      for (const incident of sorted) {
        // While we're here, tag with lat/lng properties
        incident.properties.lat = incident.geometry.coordinates[1];
        incident.properties.lng = incident.geometry.coordinates[0];
        if (!grouped[incident.properties.town]) {
          grouped[incident.properties.town] = {};
        }
        if (!grouped[incident.properties.town][incident.properties.street]) {
          grouped[incident.properties.town][incident.properties.street] = [];
        }
        grouped[incident.properties.town][incident.properties.street].push(incident);
      }
      // For any streets with multiple incidents, set their "counter" to "(# of #)"
      for (const townKey of Object.keys(grouped)) {
        for (const streetKey of Object.keys(grouped[townKey])) {
          const streetGroup = grouped[townKey][streetKey];
          if (streetGroup.length > 1) {
            for (let i = 0; i < streetGroup.length; i++) {
              streetGroup[i].properties.counter = "(" + (i + 1) + " of " + (streetGroup.length) + ")";
            }
          }
        }
      }
      return response;
    } catch (e) {
      console.error("Failed to get active incident points");
      console.log(e);
      return {
        type: "FeatureCollection",
        features: [],
      };
    }
  }

  static async GetPersonalOutageData() {
    if (store.state.user.apitoken) {
      const personal = await this.GMPCallAuthedWith404Subst("get", "/outages/me");
      return personal;
    } else {
      return [];
    }
  }
  static async SubmitOutage(accountNumber, phoneNumber) {
    // Note that this source value is different, but should be kept the same as the old site for now
    return await this.GMPCallAuthed("post", "/outages", { accountNumber, source: "WEB-OUTAGE-CENTER;Callback=" + phoneNumber });
  }

  // Planned Outages
  static async GetPlannedOutages() {
    const result = await this.GMPCallAnonymousWith404Subst("get", "/outages/planned?pending=true&expand=customersByTown", {}, true);
    return result;
  }

  static async GetPlannedOutage(outageId) {
    const result = await this.GMPCallAnonymous("get", "/outages/planned/" + outageId + "?expand=customersByTown");
    return result;
  }

  static async GetPlannedOutageShape(outageId) {
    const result = await this.GMPCallAnonymous("get", "/outages/planned?format=geojson");
    return result.features.find(item => item.properties.incidentId === outageId.toString());
  }

  // Account
  static async UpdateNickname(username, accountNumber, nickname) {
    return await this.GMPCallAuthed("post", "/users/" + encodeURIComponent(username) + "/accounts/" + accountNumber, { nickname });
  }
  static async SetPrimary(username, accountNumber) {
    return await this.GMPCallAuthed("post", "/users/" + encodeURIComponent(username) + "/accounts/" + accountNumber, { isPrimary: true });
  }
  static async AddUserAccount(username, accountNumber, phoneLastFour, nickname) {
    return await this.GMPCallAuthed("put", "/users/" + encodeURIComponent(username) + "/accounts/" + accountNumber, { accountNumber, nickname, accountVerifyValue: phoneLastFour, accountVerifyType: "phone" });
  }
  static async UpdateUser(username, fields) {
    return await this.GMPCallAuthed("post", "/users/" + encodeURIComponent(username), fields);
  }
  static async RemoveUserAccount(username, accountNumber) {
    return await this.GMPCallAuthed("delete", "/users/" + encodeURIComponent(username) + "/accounts/" + accountNumber);
  }
  static async DeleteUsername(username) {
    return await this.GMPCallAuthed("delete", "/users/" + encodeURIComponent(username));
  }
  static async GetImageBlob(url) {
    const response = await Axios({
      method: "get",
      url,
      responseType: "blob",
    });
    const filename = url.substring(url.lastIndexOf("/") + 1);
    return new File([new Blob([response.data])], filename, { type: response.headers["content-type"] });
  }
  static async UpdatePhoto(username, accountNumber, photo) {
    const formData = new FormData();
    formData.append("file", photo);
    return await this.GMPCallAuthed("post", "/users/" + encodeURIComponent(username) + "/accounts/" + accountNumber + "/photo", formData);
  }
  static async GetPhoneNumbers(accountNumber) {
    return await this.GMPCallAuthed("get", "/accounts/" + accountNumber + "/phones");
  }
  static async UpdatePhoneNumbers(accountNumber, personId, phones) {
    const params = {
      accountNumber,
      personId,
      phones,
    };
    return await this.GMPCallAuthed("put", "/accounts/" + accountNumber + "/phones", params);
  }
  static async GetMailingAddress(accountNumber) {
    return await this.GMPCallAuthed("get", "/accounts/" + accountNumber + "/address/mailing");
  }
  static async UpdateMailingAddress(accountNumber, address) {
    return await this.GMPCallAuthed("put", "/accounts/" + accountNumber + "/address/mailing", address);
  }
  static async GetPaymentArrangement(accountNumber) {
    return await this.GMPCallAuthed("get", "/accounts/" + accountNumber + "/arrangement");
  }
  static async UpdatePaymentArrangement(accountNumber, type) {
    return await this.GMPCallAuthed("put", "/accounts/" + accountNumber + "/arrangement", { selectedArrangementType: type });
  }
  static async GetNetMetering(accountNumber) {
    return await this.GMPCallAuthedWith404Subst("get", "/accounts/" + accountNumber + "/generation/credits");
  }
  static async GetAccountStatus(accountNumber) {
    const accountStatusCache = store.getters.getAccountStatus(accountNumber);
    if (accountStatusCache) { return accountStatusCache; }

    const accountStatus = await this.GMPCallAuthed("get", "/accounts/" + accountNumber + "/status");

    const payload = {
      accountNumber: accountNumber,
      accountStatus: accountStatus
    }

    store.commit("setAccountStatus", payload);
    return accountStatus;
  }
  // Stop service
  static async GetHolidays() {
    return await this.GMPCallAuthed("get", "/accounts/holidays");
  }
  static async GetStopEligible(accountNumber) {
    return await this.GMPCallAuthed("get", "/accounts/" + accountNumber + "/service");
  }
  static async ScheduleStopService(accountNumber, body) {
    return await this.GMPCallAuthed("post", "/accounts/" + accountNumber + "/service", body);
  }

  // Past Due Wizard
  static async StartPastDueWizard(accountNumber) {
    const body = {
      "action": "start",
    }
    return await this.GMPCallAuthed("post", "/accounts/" + accountNumber + "/past-due-wizard", body);
  }

  static async CompletePastDueWizard(accountNumber, body) {
    const requestBody = {
      "action": "complete",
      ...body
    }
    return await this.GMPCallAuthed("post", "/accounts/" + accountNumber + "/past-due-wizard", requestBody);
  }

  // Site feedback
  static async SubmitSiteFeedback(body) {
    return await this.GMPCallAuthed("post", "/css/feedback", body);
  }

  static async SubmitCSSLogging(accountNumber, username, action, message, isSuccess) {
    const requestBody = {
      action: action,
      username: username,
      accountNumber: accountNumber,
      success: isSuccess,
      message: message,
      source: APP_SOURCE
    };
    return await this.GMPCallAuthed("post", "/accounts/" + accountNumber + "/css-log", requestBody)
  }

  // Admin
  static async SearchAccounts(searchText) {
    return await this.GMPCallAuthedWith404Subst("get", "/accounts?q=" + encodeURI(searchText));
  }

  // External calls
  static async TokenizeCreditCard(speedpayUrl, creditNumber) {
    const response = await Axios.post(speedpayUrl, { DEBIT_ACCOUNT: creditNumber });
    return response.data;
  }
  static async LaunchSpeedpayPortal(postData, iv) {
    const form = document.createElement("form");
    form.method = "POST";
    form.action = EnvironmentConstants.SpeedpaySSOURL;
    form.target = "_blank";
    function CreateHidden(key, value) {
      const hiddenField = document.createElement("input");
      hiddenField.type = "hidden";
      hiddenField.name = key;
      hiddenField.value = value;
      return hiddenField;
    }
    form.appendChild(CreateHidden("timestamp", Date.now()));
    form.appendChild(CreateHidden("post_data", postData));
    form.appendChild(CreateHidden("IV", iv));
    document.body.appendChild(form);
    form.submit();
  }

  static async VerifyUSMailingAddress(addressToValidate) {
    let body = {
    "country_iso": "USA",
    "datasets": [
      "us-address"
    ],
    "components": {
      "unspecified": [
        addressToValidate
      ]
    },
    "options": [
      {
        "name": "prompt_set",
        "value": "default"
      },
      {
        "name": "flatten",
        "value": "true"
      },
      {
        "name": "intensity",
        "value": "extensive"
      }
    ],
    "layouts": [
      "default"
    ],
    "layout_format": "default"
  }

    const response = await Axios.post(
      `${EXPERIAN_URL}/address/validate/v1/`, body,
      { headers: { "Auth-Token": EXPERIAN_TOKEN } }
    );

    return response.data;
  }

  static async GetStatuspageSummary() {
    const response = await Axios.get(EnvironmentConstants.StatusURL + "/summary.json", { timeout: 2000 });
    return response.data;
  }

  static async GetStatuspageScheduled() {
    const response = await Axios.get(EnvironmentConstants.StatusURL + "/scheduled-maintenances/upcoming.json");
    return response.data;
  }

  static async GetWebchatStatus(queueName) {
    const response = await Axios.post(WEBCHAT_URL + "/GetQueueStatus", { QueueNumber: queueName });
    return response.data;
  }
}
