<template>
  <div class="eicproduct-container">
    <div v-if="loadState === undefined" class="eicproduct__page-loader">
      <img src="/wp-content/themes/gmptwentynineteen/assets/images/loader-grey.svg"
        class="eicproduct__page-loader--svg" />
    </div>
    <flow-error v-if="loadState === 'error'" name="Rebates" state="error"
      phonetext="For further help, please call us at" closepath="/electric-panel" />
    <div v-if="loadState === 'complete' && isCommercialAccount">
      <ElectricPanelNotEligible :currentAccount="currentAccount" />
    </div>
    <div v-if="loadState === 'complete' && !isCommercialAccount">

      <!-- SLAT 1 Verify Customer Information -->
      <section id="eicproduct-slat-1" class="eicproduct-section">
        <div class="eicproduct-slat gds-slat gds-slat--active">
          <div class="eicproduct-slat__header gds-slat__header">
            <div class="eicproduct-slat__header-left gds-flex-container">
              <div v-if="currentPage <= 1" class="eicproduct-slat__number-with-circle">
                <span>1</span>
              </div>
              <div v-if="currentPage > 1" class="eicproduct-slat__green-check-circle">
                <svg class="gds-icon">
                  <use xlink:href="/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-check" />
                </svg>
              </div>
              <div class="gds-slat__title-wrapper">
                <div class="eicproduct-slat__title gds-slat__title">Your Account Information</div>
              </div>
            </div>
            <div class="eicproduct-slat__header-right gds-flex-container">
              <div v-if="currentPage > 1" class="eicproduct-slat__header-summary gds-flex-container gds-flex-container">
                <div v-if="!userIsOTP" class="gds-body-normal gds-space-inline-m"> {{ currentAccount.nickname }}
                </div>
                <div v-if="userIsOTP" class="gds-body-normal gds-space-inline-m">Account Number: {{
                  currentAccount.accountNumber
                  }}</div>
                <button @click="gotoPage(1)"
                  class="eicproduct-slat__header-edit-button gds-button gds-round gds-secondary">Edit</button>
              </div>
              <div v-if="currentPage < 1" class="eicproduct-slat__lock-icon">
                <svg class="gds-icon">
                  <use xlink:href="/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-lock" />
                </svg>
              </div>
            </div>
          </div>

          <!-- SLAT 1 Edit Screen For Account Information -->

          <!-- User Is Guest -->
          <div v-if="currentPage === 1 && userIsOTP" class="eicproduct-slat__contents">
            <div class="eicproduct-slat__contents-inner">
              <legend class="gds-body-normal gds-space-stack-ml">Verify your GMP account information to use for your
                electric panel grant submission.</legend>
              <label :key="currentAccount.accountNumber"
                class="eicproduct-login__form--radio-label-flex gds-radio gds-space-stack-m">
                <div class="eicproduct__account-box gds-space-stack-l">
                  <span v-if="currentAccount">
                    <div class="row">
                      <div class="accountInformation column">
                        <div>
                          <b>Name on Account</b>
                        </div>
                        <span>{{ `${currentAccount.lastName}, ${currentAccount.firstName}` | titlecase() }}</span>
                      </div>

                      <div class="accountInformation column">
                        <div>
                          <b>Service Address</b>
                        </div>
                        <span>{{ currentAccount.address | addressFormat({ separator: ", " }) }}</span>
                      </div>
                      <div class="accountInformation column">
                        <div>
                          <b>Account Number</b>
                        </div>
                        <span>{{ currentAccount.accountNumber }}</span>
                      </div>
                    </div>
                  </span>
                </div>
              </label>
              <button @click="finishPage('1', 2)"
                class="eicproduct__method-next-btn gds-button gds-compact">Next</button>
            </div>
          </div>

          <!-- User Has WWW Account -->
          <div v-if="currentPage === 1 && !userIsOTP" class="eicproduct-slat__contents">
            <div class="eicproduct-slat__contents-inner">
              <legend class="gds-body-normal gds-space-stack-ml">Verify your GMP account information to use for your
                electric panel submission.</legend>
              <div class="eicproduct__account-box gds-space-stack-l">
                <span v-if="currentAccount">
                  <div class="row-m">
                    <div class="account-info-row">
                      <div class="accountInformation column">
                        <div>
                          <b>Name on Account</b>
                        </div>
                        <span>{{ `${currentAccount.lastName}, ${currentAccount.firstName}` | titlecase() }}</span>
                      </div>
                      <div class="accountInformation column">
                        <div>
                          <b>Service Address</b>
                        </div>
                        <span>{{ currentAccount.address | addressFormat({ separator: ", " }) }}</span>
                      </div>
                      <div class="accountInformation column">
                        <div>
                          <b>Account Number</b>
                        </div>
                        <span>{{ currentAccount.accountNumber }}</span>
                      </div>
                    </div>
                    <div v-if="accounts.length > 1" @click="chooseAccount()"
                      class="inline accountInformation eicproduct__edit-account-details">
                      <button class="gds-button gds-text-button choose-account-button">
                        <svg class="eicproduct__edit-icon-inline gds-icon">
                          <use xlink:href="/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-edit" />
                        </svg>
                        <span v-if="accounts.length > 1">Switch Account</span>
                      </button>
                    </div>
                  </div>
                </span>
              </div>
              <button @click="finishPage('1', 2)"
                class="eicproduct__method-next-btn gds-button gds-compact">Next</button>
            </div>
          </div>
        </div>
      </section>

      <!-- SLAT 2 Electric Work Description -->
      <section id="eicproduct-slat-2" class="eicproduct-section">
        <div class="eicproduct-slat gds-slat gds-slat--active">
          <div class="eicproduct-slat__header gds-slat__header">
            <div class="eicproduct-slat__header-left gds-flex-container">
              <div v-if="currentPage <= 2" class="eicproduct-slat__number-with-circle">
                <span>2</span>
              </div>
              <div v-if="currentPage > 2" class="eicproduct-slat__green-check-circle">
                <svg class="gds-icon">
                  <use xlink:href="/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-check" />
                </svg>
              </div>
              <div class="gds-slat__title-wrapper">
                <div class="eicproduct-slat__title gds-slat__title">Electrical Work Description</div>
              </div>
            </div>
            <div class="eicproduct-slat__header-right gds-flex-container">
              <div v-if="currentPage > 2" class="eicproduct-slat__header-summary gds-flex-container gds-flex-container">
                <button @click="gotoPage(2)"
                  class="eicproduct-slat__header-edit-button gds-button gds-round gds-secondary">Edit</button>
              </div>

              <div v-if="currentPage < 2" class="eicproduct-slat__lock-icon">
                <svg class="gds-icon">
                  <use xlink:href="/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-lock" />
                </svg>
              </div>
            </div>
          </div>
          <div v-if="currentPage === 2" class="eicproduct-slat__contents">
            <div class="eicproduct-slat__contents-inner">
              <div class="rebate-slap__inner-row">
                <div>
                  <form data-vv-scope="page2">
                    <fieldset class="gds-fieldset gds-space-stack-s">
                      <div class="gds-space-stack-m">
                        <label class="gds-input-field">
                          <span class="gds-input-field__label">Have you already secured an electrician for your
                            electrical
                            upgrades?</span>
                          <div class="gds-space-stack-ml">
                            <div class="flex-item margin-right">
                              <select 
                                v-model="productSubmission.electricalWork.hasElectrician"
                                @change="handleChangeHasElectrician()"
                                class="eicproduct-input-field-l__select gds-input-field" v-validate="'required'"
                                name="electrician secured" autofocus>
                                <option disabled value="">Select...</option>
                                <option value="yes">Yes</option>
                                <option value="no">No</option>
                              </select>
                            </div>
                          </div>
                          <div v-if="errors.first('electrician secured', 'page2')" class="validation-error">{{
                            errors.first("electrician secured", "page2")
                            }}</div>
                        </label>
                      </div>
                      <div class="gds-space-stack-m">
                        <label v-if="hasElectrician" class="gds-input-field gds-space-stack-l">
                          <span class="gds-input-field__label">Please enter the name of the Electrician that you are
                            working with.</span>
                          <input @keyup="selectElectrician($event)"
                            v-model="productSubmission.electricalWork.electricanName"
                            @input="$emit('touched', true); filterElectricians($event);"
                            @change="filterElectricians($event)" v-validate="'required'" name="electrician"
                            class="gds-input-field__input gds-space-stack-m" type="text" autocomplete="off"
                            placeholder="Type Here..." />
                          <div class="typeAhead-container" v-if="filters.electrician && filters.electrician.length > 0">
                            <div class="typeAhead-card gds-space-stack-m"
                              :class="{ 'active-dealership typeAhead-card gds-space-stack-m': currentElectrician === electrician.id }"
                              v-for="(electrician) in filters.electrician" :key="electrician.id"
                              @click="setElectrician(electrician)">
                              <div class="typeAhead-text">{{ electrician.name }}</div>
                            </div>
                          </div>
                          <div v-if="errors.first('electrician', 'page2')" class="validation-error">{{
                            errors.first("electrician", "page2") }}</div>
                        </label>
                      </div>
                      <div v-if="!hasElectrician" class="gds-space-stack-m">
                        <label class="gds-input-field">
                          <span class="gds-input-field__label">Do you need help finding a qualified electrician?</span>
                          <div class="gds-space-stack-l">
                            <select 
                              v-model="productSubmission.electricalWork.needsElectricianHelp"
                              class="eicproduct-input-field__select" 
                              v-validate="'required'"
                              name="need help finding electrician" 
                              autofocus>
                              <option disabled value="">Select...</option>
                              <option value="yes">Yes</option>
                              <option value="no">No</option>
                            </select>
                          </div>
                          <div v-if="errors.first('need help finding electrician', 'page2')" class="validation-error">{{
                            errors.first("need help finding electrician", "page2")
                            }}</div>
                        </label>
                      </div>
                      <div class="gds-space-stack-l">
                        <span class="gds-input-field__label">Select one or more of the options below that best describe
                          the
                          reasons for upgrading your electrical system.</span>
                        <div v-for="(item) in upgradeReasonItems" :key="item">
                          <label class="gds-checkbox gds-checkbox-flex gds-space-stack-l">
                            <input 
                              type="checkbox" name="upgrade reasons" 
                              v-validate="'required'" 
                              :value="item"
                              v-model="productSubmission.electricalWork.reasonForPanelUpgrade" />
                            <span class="gds-checkbox__faux"></span>
                            <span>{{ item }}</span>
                          </label>
                        </div>
                        <div v-if="errors.first('upgrade reasons', 'page2')" class="validation-error">{{
                          errors.first("upgrade reasons", "page2") }}
                        </div>
                      </div>
                      <div class="gds-space-stack-l">
                        <span class="gds-input-field__label">Select one or more of the options below that describe the
                          planned
                          electrical upgrades.</span>
                        <div v-for="(item) in workScopeItems" :key="item">
                          <label class="gds-checkbox">
                            <input 
                              type="checkbox" name="planned electrical upgrades" 
                              v-validate="'required'"
                              :value="item" 
                              v-model="productSubmission.electricalWork.selectedWorkItems" />
                            <span class="gds-checkbox__faux"></span>
                            <span>{{ item }}</span>
                          </label>
                        </div>
                        <div v-if="errors.first('planned electrical upgrades', 'page2')" class="validation-error">{{
                          errors.first("planned electrical upgrades", "page2") }}
                        </div>
                      </div>
                    </fieldset>
                  </form>
                </div>
                <button @click="finishPage('2', 3)" class="gds-button gds-compact">Next</button>
              </div>
            </div>
          </div>
        </div>
      </section>

      <!-- SLAT 3 Property Info -->
      <section id="eicproduct-slat-3" class="eicproduct-section">
        <div class="eicproduct-slat gds-slat gds-slat--active">
          <div class="eicproduct-slat__header gds-slat__header">
            <div class="eicproduct-slat__header-left gds-flex-container">
              <div v-if="currentPage <= 3" class="eicproduct-slat__number-with-circle">
                <span>3</span>
              </div>
              <div v-if="currentPage > 3" class="eicproduct-slat__green-check-circle">
                <svg class="gds-icon">
                  <use xlink:href="/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-check" />
                </svg>
              </div>
              <div class="gds-slat__title-wrapper">
                <div class="eicproduct-slat__title gds-slat__title">Property Information</div>
              </div>
            </div>
            <div class="eicproduct-slat__header-right gds-flex-container">
              <div v-if="currentPage > 3" class="eicproduct-slat__header-summary gds-flex-container gds-flex-container">
                <button @click="gotoPage(3)"
                  class="eicproduct-slat__header-edit-button gds-button gds-round gds-secondary">Edit</button>
              </div>
              <div v-if="currentPage < 3" class="eicproduct-slat__lock-icon">
                <svg class="gds-icon">
                  <use xlink:href="/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-lock" />
                </svg>
              </div>
            </div>
          </div>
          <div v-if="currentPage === 3" class="eicproduct-slat__contents">
            <div class="eicproduct-slat__contents-inner">
              <div class="gds-space-stack-l">
                <form data-vv-scope="page3">
                  <div class="row-s">
                    <div class="gds-space-stack-m">
                      <label class="gds-input-field">
                        <span class="gds-input-field__label">Do you own the property where the electrical work will be
                          done?</span>
                        <div class="gds-space-stack-ml">
                          <select 
                            v-model="productSubmission.householdInfo.isAccountHolderPropertyOwner"
                            class="eicproduct-input-field__select" 
                            v-validate="'required'" 
                            name="property owner"
                            autofocus>
                            <option disabled value="">Select...</option>
                            <option>Yes</option>
                            <option>No</option>
                          </select>
                        </div>
                      </label>
                      <div v-if="errors.first('property owner', 'page3')" class="validation-error">{{
                          errors.first("property owner", "page3") }}
                      </div>
                      <label class="gds-input-field">
                        <span class="gds-input-field__label">Is the property being upgraded a single or multi-family home?</span>
                        <div class="gds-space-stack-ml">
                          <select 
                            v-model="productSubmission.electricalWork.housingType"
                            class="eicproduct-input-field__select" 
                            v-validate="'required'" 
                            name="housing type"
                            autofocus>
                            <option disabled value="">Select...</option>
                            <option>single family</option>
                            <option>multi-family</option>
                          </select>
                        </div>
                      </label>
                      <div v-if="errors.first('housing type', 'page3')" class="validation-error">{{
                          errors.first("housing type", "page3") }}
                      </div>
                      <label v-if="isMultiFamilyHome" class="gds-input-field">
                        <span class="gds-input-field__label">How many moderate income housing units will be
                          upgraded?</span>
                        <div class="gds-space-stack-ml">
                          <select
                            v-model="productSubmission.electricalWork.numberOfModerateIncomeHousingUnits"
                            v-validate="'required'"
                            name="numberOfModerateIncomeHousingUnits"
                            data-vv-as="Number of Moderate Income Housing Units"
                            class="eicproduct-input-field__select"
                          >
                            <option value="" disabled selected>Select a value</option>
                            <option v-for="n in numberOptions" :key="n" :value="n">
                              {{ n }}
                            </option>
                          </select>
                        </div>
                      </label>
                      <div v-if="errors.first('moderate income housing units', 'page3')"
                        class="eicproduct-validation-error" style="max-width:80%">{{ errors.first("moderate income housing units", "page3") }}
                      </div>
                      <label v-if="isMultiFamilyHome" class="gds-input-field">
                        <span class="gds-input-field__label">How many low income housing units will be
                          upgraded?</span>
                        <div class="gds-space-stack-ml">
                          <select
                            v-model="productSubmission.electricalWork.numberOfLowIncomeHousingUnits"
                            v-validate="'required'"
                            name="numberOfLowIncomeHousingUnits"
                            data-vv-as="Number of Low Income Housing Units"
                            class="eicproduct-input-field__select"
                          >
                            <option value="" disabled selected>Select a value</option>
                            <option v-for="n in numberOptions" :key="n" :value="n">
                              {{ n }}
                            </option>
                          </select>
                        </div>
                      </label>
                      <div v-if="errors.first('low income housing units', 'page3')"
                        class="eicproduct-validation-error" style="max-width:80%">{{ errors.first("low income housing units", "page3") }}
                      </div>
                    </div>
                  </div>
                  <div class="slat-title-wrapper">
                    <div class="eicproduct-title gds-space-stack-l">Please update your mailing address if it is different from your home's address?</div>
                  </div>
                  <label class="gds-input-field">
                    <div class="gds-space-stack-ml">
                      <span class="gds-input-field__label">Country</span>
                      <select 
                        v-model="productSubmission.account.mailingAddress.country"
                        :class="productSubmission.account.mailingAddress.country === 'USA' ? 'eicproduct-input-field__select-country-us gds-input-field' : productSubmission.account.mailingAddress.country === 'CAN' ? 'eicproduct-input-field__select-country-ca gds-input-field' : 'eicproduct-input-field__select-s gds-input-field'"
                        autofocus>
                        <option :value="'USA'" selected
                          data-thumbnail="/wp-content/themes/gmptwentynineteen/assets/images/flag_us.svg">United States
                        </option>
                        <option value="CAN">Canada</option>
                        <option value="FOR">Foreign</option>
                      </select>
                      <div v-if="!productSubmission.account.mailingAddress.country" class="eicproduct-validation-error">
                        {{ errors.first("country", "page3") }}
                      </div>
                    </div>
                  </label>
                  <label class="gds-input-field">
                    <div class="gds-space-stack-ml">
                      <span class="gds-input-field__label">Street Address</span>
                      <input 
                        v-model="productSubmission.account.mailingAddress.street1" 
                        @input="$emit('touched', true)"
                        v-validate="'required'" 
                        name="street address"
                        class="eicproduct-input-field gds-input-field__input" 
                        type="text" placeholder="Type Here..." />
                      <div v-if="errors.first('street address', 'page3')" class="validation-error">{{
                        errors.first("street address", "page3") }}
                      </div>
                    </div>
                  </label>
                  <label class="gds-input-field">
                    <div class="gds-space-stack-ml">
                      <span class="gds-input-field__label">Suite Apt</span>
                      <input 
                        v-model="productSubmission.account.mailingAddress.street2" 
                        @input="$emit('touched', true)"
                        name="street 2" 
                        class="eicproduct-input-field gds-input-field__input" 
                        type="text"
                        placeholder="Type Here..." />
                    </div>
                  </label>
                  <div class="row-l">
                    <div class="flex-item-l">
                      <label class="gds-input-field-flex">
                        <div class="gds-space-stack-ml">
                          <span class="gds-input-field__label">City</span>
                          <input v-model="productSubmission.account.mailingAddress.city" @input="$emit('touched', true)"
                            v-validate="'required'" name="city" class="gds-input-field__input" type="text"
                            placeholder="Type Here..." />
                          <div v-if="errors.first('city', 'page3')" class="validation-error">{{ errors.first("city",
                            "page3")
                            }}
                          </div>
                        </div>
                      </label>
                    </div>
                    <div class="flex-item-s gds-space-stack-m">
                      <label v-if="productSubmission.account.mailingAddress.country === 'USA'" class="gds-input-field">
                        <div class="gds-space-stack-ml">
                          <span class="gds-input-field__label">State</span>
                          <select v-model="productSubmission.account.mailingAddress.state"
                            @change="$emit('touched', true)" class="eicproduct-input-field__select gds-input-field">
                            <option value disabled selected>Select State...</option>
                            <option v-for="state of stateList" :key="state" :value="state">{{ state }}</option>
                          </select>
                          <div v-if="!productSubmission.account.mailingAddress.state"
                            class="eicproduct-validation-error">{{ errors.first("state", "page3") }}
                          </div>
                        </div>
                      </label>
                      <label v-if="productSubmission.account.mailingAddress.country === 'CAN'" class="gds-input-field">
                        <div class="gds-space-stack-ml">
                          <span class="gds-input-field__label">Province</span>
                          <select v-model="productSubmission.account.mailingAddress.state"
                            @change="$emit('touched', true)" class="eicproduct-input-field__select gds-input-field">
                            <option value disabled selected>Select Province...</option>
                            <option v-for="province of provinceList" :key="province" :value="province">{{ province }}
                            </option>
                          </select>
                          <div v-if="!productSubmission.account.mailingAddress.state"
                            class="eicproduct-validation-error">{{ errors.first("province", "page3") }}</div>
                        </div>
                      </label>
                    </div>
                    <div class="flex-item-m">
                      <label class="gds-input-field">
                        <div class="gds-space-stack-ml">
                          <span class="gds-input-field__label">Zipcode</span>
                          <input v-model="productSubmission.account.mailingAddress.zip" @input="$emit('touched', true)"
                            v-validate="'required|numeric|length:5'" name="zipcode"
                            class="gds-input-field__input eicproduct-mailing-zip" type="text" placeholder="Type Here..."
                            autocomplete="postal-code" />
                          <div v-if="errors.first('zipcode', 'page3')" class="validation-error">{{
                            errors.first("zipcode",
                              "page3")
                            }}</div>
                        </div>
                      </label>
                      <div
                        v-if="!productSubmission.account.mailingAddress.zip && productSubmission.account.mailingAddress.country === 'USA'"
                        class="eicproduct-validation-error">{{ errors.first("zip", "page3") }}</div>
                      <div
                        v-if="!productSubmission.account.mailingAddress.zip && productSubmission.account.mailingAddress.country === 'CAN'"
                        class="eicproduct-validation-error">{{ errors.first("canadianZip", "page3") }}</div>
                    </div>
                  </div>

                </form>
                <button @click="finishPage('3', 4)"
                  class="eicproduct__method-next-btn gds-button gds-compact">Next</button>
              </div>
            </div>
          </div>
        </div>
      </section>

      <!-- SLAT 4 Household Income Info -->
      <section v-if="isSingleFamilyHome" id="eicproduct-slat-4" class="eicproduct-section">
        <div class="eicproduct-slat gds-slat gds-slat--active">
          <div class="eicproduct-slat__header gds-slat__header">
            <div class="eicproduct-slat__header-left gds-flex-container">
              <div v-if="currentPage <= 4" class="eicproduct-slat__number-with-circle">
                <span>4</span>
              </div>
              <div v-if="currentPage > 4" class="eicproduct-slat__green-check-circle">
                <svg class="gds-icon">
                  <use xlink:href="/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-check" />
                </svg>
              </div>
              <div class="gds-slat__title-wrapper">
                <div class="eicproduct-slat__title gds-slat__title">Household Income</div>
              </div>
            </div>
            <div class="eicproduct-slat__header-right gds-flex-container">
              <div v-if="currentPage > 4" class="eicproduct-slat__header-summary gds-flex-container gds-flex-container">
                <button @click="gotoPage(4)"
                  class="eicproduct-slat__header-edit-button gds-button gds-round gds-secondary">Edit</button>
              </div>
              <div v-if="currentPage < 4" class="eicproduct-slat__lock-icon">
                <svg class="gds-icon">
                  <use xlink:href="/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-lock" />
                </svg>
              </div>
            </div>
          </div>
          <div v-if="currentPage === 4" class="eicproduct-slat__contents">
            <div class="eicproduct-slat__contents-inner">
              <div class="gds-space-stack-l">
                <form data-vv-scope="page4">
                  <div class="row-s">
                    <div class="gds-space-stack-m">
                      <label v-if="!isPremiseCountyFound" class="gds-input-field">
                        <span class="gds-input-field__label">What county does your home reside in?</span>
                        <div class="gds-space-stack-ml">
                          <select 
                            v-model="productSubmission.account.premiseAddress.county" 
                            @change="handleCountyChange"
                            v-validate="'required'"
                            name="county" 
                            class="eicproduct-input-field__select" 
                            autofocus>
                            <option value="undefined" disabled>Select...</option>
                            <option v-for="county in countyList" :key="county">{{ county }}</option>
                          </select>
                        </div>
                        <div v-if="errors.first('county', 'page4')" class="validation-error">{{
                          errors.first("county", "page4") }}
                        </div>
                      </label>
                      <label class="gds-input-field">
                        <span class="gds-input-field__label">How many people live in your home?</span>
                        <div class="gds-space-stack-ml">
                          <select 
                            v-model="productSubmission.householdInfo.numberInHousehold"
                            @change="buildIncomeDropDown()"
                            class="eicproduct-input-field__select" v-validate="'required'" name="number in household"
                            autofocus>
                            <option disabled value=undefined>Select...</option>
                            <option v-for="(item, index) in numberInHousehold" :key="index" :value="item">{{ item }}
                            </option>
                          </select>
                        </div>
                        <div v-if="errors.first('number in household', 'page4')" class="validation-error">{{
                          errors.first("number in household", "page4") }}
                        </div>
                      </label>
                      <label class="gds-input-field">
                            <span class="gds-input-field__label">Gross Household Income in {{ lastYear }}</span>
                            <select 
                              v-model="selectedIncome"
                              class="eicproduct-input-field__select gds-space-stack-ml" autofocus
                              v-validate="'required'" 
                              name="gross household income" 
                              key="gross household income"
                              :disabled="!productSubmission.householdInfo.numberInHousehold ? true : false">
                              <option v-for="(option, index) of incomeAmounts" :key="index"
                                :value="option">{{ option.incomeOption }}
                              </option>
                            </select>
                            <div v-if="errors.first('gross household income', 'page4')"
                              class="eicproduct-validation-error">
                              {{ errors.first("gross household income", "page4") }}</div>
                          </label>
                    </div>
                  </div>
                </form>
                <button @click="finishPage('4', 5)"
                  class="eicproduct__method-next-btn gds-button gds-compact">Next</button>
              </div>
            </div>
          </div>
        </div>
      </section>


      <!-- SLAT 5 Upload Documents  -->
      <section id="eicproduct-slat-5" class="eicproduct-section">
        <div class="eicproduct-slat gds-slat gds-slat--active">
          <div class="eicproduct-slat__header gds-slat__header">
            <div class="eicproduct-slat__header-left gds-flex-container">
              <div v-if="currentPage <= 5" class="eicproduct-slat__number-with-circle">
                <span>{{ supportingDocumentsSlatNumber }}</span>
              </div>
              <div v-if="currentPage > 5" class="eicproduct-slat__green-check-circle">
                <svg class="gds-icon">
                  <use xlink:href="/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-check" />
                </svg>
              </div>
              <div class="gds-slat__title-wrapper">
                <div class="eicproduct-slat__title gds-slat__title">Supporting Documents</div>
                <ModalEICProducts modalName="fileLoadTooBig" v-if="showFileLoadTooBigModal" :close="closeModal"
                  :modalMessage="`Your total file size is ${this.$options.filters.fileSizeFormat(totalFileSize)}. Please make sure the combined size of all uploaded files is less than ${this.$options.filters.fileSizeFormat(maxFileLoadSize)}.`" />
                <ModalEICProducts modalName="duplicateFilename" v-if="showDuplicateFilenameModal" :close="closeModal"
                  modalMessage="Each file that is uploaded must have a unique file name." />
              </div>
            </div>
            <div class="eicproduct-slat__header-right gds-flex-container">
              <div v-if="currentPage > 5" class="eicproduct-slat__header-summary gds-flex-container gds-flex-container">
                <div v-if="documents.length > 1" class="gds-body-normal gds-space-inline-s">{{ `${this.documents.length}
                  Files Uploaded`}}</div>
                <div v-if="documents.length === 1" class="gds-body-normal gds-space-inline-s">
                  {{ `${this.documents.length} File Uploaded` }}</div>
                <button @click="gotoPage(5)"
                  class="eicproduct-slat__header-edit-button gds-button gds-round gds-secondary">Edit</button>
              </div>
              <div v-if="currentPage < 5" class="eicproduct-slat__lock-icon">
                <svg class="gds-icon">
                  <use xlink:href="/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-lock" />
                </svg>
              </div>
            </div>
          </div>
          <div v-if="currentPage === 5" class="eicproduct-slat__contents">
            <div class="gds-space-stack-m">
              <form data-vv-scope="page5">
                <label class="gds-input-field">
                  <span class="gds-input-field__label">Do you have any documents such as quotes that you would
                    like to upload?</span>
                  <div class="gds-space-stack-ml">
                    <select 
                      v-model="productSubmission.electricalWork.haveSupportingDocuments"
                      class="eicproduct-input-field__select" 
                      v-validate="'required'" 
                      name="supporting documents"
                      autofocus>
                      <option disabled value="">Select...</option>
                      <option>Yes</option>
                      <option>No</option>
                    </select>
                    <div v-if="errors.first('supporting documents', 'page5')" class="validation-error">{{
                      errors.first("supporting documents", "page5") }}
                    </div>
                  </div>
                </label>
              </form>
            </div>
            <div class="eicproduct-slat__contents-inner">
              <div class="gds-space-stack-l">
                <div class="slat-title-wrapper">
                  <div class="eicproduct-slat__contents-inner">
                    <div class="gds-space-stack-l">
                      <div v-if="haveSupportingDocuments" class="slat-title-wrapper">
                        <form>
                          <div id="fileUploader">
                            <File-Uploader 
                              ref="fileUploader" 
                              :uploadDocumentList="uploadDocumentList"
                              :docTypes="documentTypes" 
                              :allowCombined="false"
                              :headingTitle="'Please provide any supporting documents'"
                              @file-list-changed="updateFileList">
                            </File-Uploader>
                          </div>
                        </form>
                      </div>
                      <button @click="finishPage('5', 6)"
                        class="eicproduct__method-next-btn gds-button gds-compact">Done</button>
                      <button v-if="haveSupportingDocuments" @click="addFile()"
                        class="eicproduct__method-next-btn gds-button gds-compact">
                        Upload Another File</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <!-- SLAT 6 Review & Submit-->
      <section id="eicproduct-slat-6" class="eicproduct-section">
        <div class="eicproduct-slat gds-slat gds-slat--active">
          <div class="eicproduct-slat__header gds-slat__header">
            <div class="eicproduct-slat__header-left gds-flex-container">
              <div v-if="currentPage <= 6" class="eicproduct-slat__number-with-circle">
                <span>{{ reviewAndSubmitSlatNumber }}</span>
              </div>
              <div v-if="currentPage > 6" class="eicproduct-slat__green-check-circle">
                <svg class="gds-icon">
                  <use xlink:href="/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-check" />
                </svg>
              </div>
              <div class="gds-slat__title-wrapper">
                <div class="eicproduct-slat__title gds-slat__title">Review & Submit</div>
              </div>
            </div>
            <div class="eicproduct-slat__header-right gds-flex-container">
              <div v-if="currentPage < 6" class="eicproduct-slat__lock-icon">
                <svg class="gds-icon">
                  <use xlink:href="/wp-content/themes/gmptwentynineteen/assets/symbol-defs.svg#gds-icon-lock" />
                </svg>
              </div>
            </div>
          </div>

          <div v-if="currentPage === 6" class="eicproduct-slat__contents">
            <div class="eicproduct-slat__contents-inner">
              <div class="gds-space-stack-l">
                <div>
                  <div class="slat-title-wrapper">
                    <div class="eicproduct-title gds-space-stack-xl">Please verify that your contact information is
                      accurate in case we need additional information.</div>
                  </div>
                  <label class="gds-input-field">
                    <div class="gds-space-stack-m">
                      <span class="gds-input-field__label">Email Address</span>
                      <input 
                        v-model="productSubmission.propertyOwner.confirmationEmailAddress"
                        @input="$emit('touched', true)" 
                        v-validate="'required|email|max:74'" 
                        name="email address"
                        class="eicproduct-input-field gds-input-field__input" 
                        type="email"
                        placeholder="Enter email address" autocomplete="email" 
                        ref="email" />
                      <div v-if="errors.first('email address', 'page6')" class="validation-error">{{
                        errors.first("email address", "page6")
                      }}</div>
                    </div>
                  </label>
                  <label class="gds-input-field">
                    <div class="gds-space-stack-m">
                      <span class="gds-input-field__label">Phone Number</span>
                      <the-mask 
                        v-model="productSubmission.propertyOwner.contactPhoneNumber" 
                        :mask="'+1 (###) ###-####'"
                        @input.native="touched = true" 
                        v-validate="'required|min:10'" data-vv-validate-on="input"
                        :name="'phone number'" class="eicproduct-input-field gds-input-field__input" 
                        type="tel"
                        placeholder="Your phone number" />
                      <div v-if="errors.first('phone number', 'page6')" class="validation-error">{{
                        errors.first("phone number", "page6")
                      }}</div>
                    </div>
                  </label>
                </div>
                <div class="gds-space-stack-m">
                  <label class="gds-checkbox gds-checkbox-thin">
                    <input v-model="regulatoryDataSharing" 
                      v-validate="'required'" 
                      type="checkbox"
                      name="regulatory data sharing" />
                    <span class="gds-checkbox__faux"></span>
                    <span class="gds-font-size-ml regulatory-text1">
                      {{ REGULATORY_REBATE_SHARING_TEXT1 }}
                    </span>
                    <p class="gds-font-size-ml regulatory-text2">
                      {{ REGULATORY_REBATE_SHARING_TEXT2 }}
                    </p>
                    <div v-if="errors.first('regulatory data sharing', 'page6')" class="eicproduct-validation-error">{{
                      errors.first("regulatory data sharing", "page6")
                    }}</div>
                  </label>
                </div>
                <button v-if="!pendingSubmit" 
                  @click="handleSubmit" 
                  type="submit"
                  :disabled="!regulatoryDataSharing || errors.any()"
                  class="gds-space-stack-ml eicproduct__method-next-btn gds-button gds-compact">
                  Submit Upgrade Request
                </button>
                <button v-if="pendingSubmit" 
                  class="gds-button [modifier class] gds-loading">
                  <span class="gds-button__text">Submit Upgrade Request</span>
                  <figure class="gds-button__icon"></figure>
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import { ErrorBag } from "vee-validate";
import GMPAPI from "../../../services/gmpapi";
import { DumpError } from "../../../utilities";
import Datepicker from 'vuejs-datepicker';
import FileUploader from "../FileUploader.vue";
import { electricalQuote, regulatoryRebateSharingText1, regulatoryRebateSharingText2, COUNTY_LIST, STATE_LIST, PROVINCE_LIST } from "../constants.js";
import { GMPPhoneNumber } from "../../../environment"
import ModalDocumentChecklist from "./ModalDocumentChecklist.vue"
import ElectricPanelNotEligible from "./ElectricPanelNotEligible.vue";
import ModalEICProducts from "../ModalEICProducts.vue";
import { getConfigValue } from "../../../services/featureflags";
import { ToServerDateTruncate } from "../../../utilities";


export default {
  name: "ElectricPanelGrantRequest",
  components: {
    Datepicker,
    ModalDocumentChecklist,
    ElectricPanelNotEligible,
    FileUploader,
    ModalEICProducts,
  },
  data() {
    return {
      GMPPhoneNumber,
      REGULATORY_REBATE_SHARING_TEXT1: undefined,
      REGULATORY_REBATE_SHARING_TEXT1_DEFAULT: regulatoryRebateSharingText1,
      REGULATORY_REBATE_SHARING_TEXT2: undefined,
      REGULATORY_REBATE_SHARING_TEXT2_DEFAULT: regulatoryRebateSharingText2,
      SINGLE_FAMILY: 'single family',
      MULTI_FAMILY: 'multi-family',
      YES: 'yes',
      NO: 'no',
      SIX_HOURS: 1000 * 60 * 60 * 6,
      MAX_NUMBER_IN_HOUSEHOLD: '6 or more',
      supportingDocumentsSlatNumber: 4,
      reviewAndSubmitSlatNumber: 5,
      lastYear: new Date().getFullYear() - 1,
      currentSlatNumber: 2,
      nextSlatNumber: undefined,
      regulatoryDataSharing: undefined,
      isKeyboarding: false,
      loadState: undefined,
      pendingSubmit: undefined,
      touched: false,
      pendingSave: undefined,
      filters: [{
        electrician: undefined,
      }],
      filteredElectricians: [],
      electricians: [],

      totalFileSize: undefined,
      maxFileLoadSize: undefined,
      numberInHousehold: ['1', '2', '3', '4', '5', '6 or more'],
      numberInHouseholdSelected: undefined,
      selectedIncome: undefined,
      incomeAmounts: [],
      incomeIncentiveLimits: [],
      stateList: STATE_LIST,
      provinceList: PROVINCE_LIST,
      countyList: COUNTY_LIST,

      upgradeReasonItems: [],
      workScopeItems: [],

      showFileLoadTooBigModal: false,
      showDuplicateFilenameModal: false,

      selectedFile: undefined,
      file: undefined,
      documents: [],
      documentTypes: [],
      uploadDocumentList: [
        {
          documentName: electricalQuote,
          displayName: 'Supporting Document 1',
          error: undefined,
          file: null,
          fileName: null,
          fileSize: null,
        },
      ],
      selectedDocumentType: undefined,

      missingDocuments: [],

      customerConfirmation: undefined,
      isPremiseCountyFound: false,

      productSubmission: {
        submissionType: 'electricPanelGrantRequest',
        submissionSource: undefined,
        account: {
          accountNumber: undefined,
          firstName: undefined,
          lastName: undefined,
          premiseAddress: {
            street1: undefined,
            city: undefined,
            county: undefined,
            country: undefined,
            state: undefined,
            zip: undefined
          },
          mailingAddress: {
            street1: undefined,
            city: undefined,
            country: undefined,
            state: undefined,
            zip: undefined
          }
        },
        electricalWork: {
          reasonForPanelUpgrade: [],
          selectedWorkItems: [],
          hasElectrician: "",
          needsElectricianHelp: "",
          haveSupportingDocuments: "",
          electricanName: undefined,
          housingType: "",
          numberOfLowIncomeHousingUnits: "",
          numberOfModerateIncomeHousingUnits: "",
        },
        householdInfo: {
          incomeLevelId: undefined,
          incomeLevel: undefined,
          numberInHousehold: undefined,
          grossHouseholdIncome: undefined,
          isAccountHolderPropertyOwner: "",
        },
        propertyOwner: {
          confirmationEmailAddress: undefined,
          contactPhoneNumber: undefined,
        }
      }
    }
  },
  computed: {
    userIsOTP() {
      return this.$store.state.user.userinfo.isOTP;
    },
    currentAccount() {
      return this.$store.state.user.currentAccount;
    },
    accounts() {
      return this.$store.state.user.accounts;
    },
    currentPage() {
      return Number(this.$route.params.page);
    },
    isCommercialAccount() {
      return this.currentAccount.customerClass === "C";
    },
    hasElectrician() {
      return this.productSubmission.electricalWork.hasElectrician &&
        this.productSubmission.electricalWork.hasElectrician.toLowerCase() === this.YES;
    },
    haveSupportingDocuments() {
      return this.productSubmission.electricalWork.haveSupportingDocuments &&
        this.productSubmission.electricalWork.haveSupportingDocuments.toLowerCase() === this.YES;
    },
    isSingleFamilyHome() {
      return this.productSubmission.electricalWork.housingType 
                && this.productSubmission.electricalWork.housingType.toLowerCase() === this.SINGLE_FAMILY;
    },
    isMultiFamilyHome() {
      return this.productSubmission.electricalWork.housingType 
                && this.productSubmission.electricalWork.housingType.toLowerCase() === this.MULTI_FAMILY;
    },
    numberOptions() {
      return Array.from({ length: 51 }, (_, i) => i);
    }
  },

  watch: {
    isSingleFamilyHome(newVal) {
      if (newVal === false) {
        this.supportingDocumentsSlatNumber = 4;
        this.reviewAndSubmitSlatNumber = 5;
      } else {
        this.supportingDocumentsSlatNumber = 5;
        this.reviewAndSubmitSlatNumber = 6;
      }
    },
    selectedIncome(newVal) {
      if (newVal) {
        this.productSubmission.householdInfo.incomeLevelId = newVal.incomeLevelId;
        this.productSubmission.householdInfo.grossHouseholdIncome = newVal.incomeAmount;
        this.productSubmission.householdInfo.incomeLevel = newVal.incomeLevel;
      }
    },
  },

  created() {
    window.addEventListener("keydown", (e) => {
      if (this.currentPage === 2) {
        this.isKeyboarding = true;
      } else {
        this.isKeyboarding = false;
      }
    });
  },

  async mounted() {
    this.initializePage();

    try {
      this.setSubmissionAccount();
      await this.fetchInitialData();
      this.loadProductSubmission();

      this.buildIncomeDropDown();
      this.loadState = "complete";
    } catch (err) {
      this.handleError(err);
    }

    this.focusAndReload();
  },
  methods: {
    initializePage() {
      this.$store.commit("setLastValidPage", "1");
      document.addEventListener("click", this.hideTypeAhead);
    },

    async fetchInitialData() {
      this.loadState = undefined;
      await this.getData();
    },

    loadProductSubmission() {
      const localStorageProductSubmissionObj = this.getWithExpiry("localProductSubmission") || undefined;

      if (
        localStorageProductSubmissionObj &&
        localStorageProductSubmissionObj.submissionType === "electricPanelGrantRequest" &&
        localStorageProductSubmissionObj.account.accountNumber === this.currentAccount.accountNumber
      ) {
        this.productSubmission = localStorageProductSubmissionObj;
        const effectiveDate = ToServerDateTruncate(new Date());
        this.getEICMeta(
          this.productSubmission.submissionType,
          effectiveDate,
          this.productSubmission.account.premiseAddress.county
        );
      }
    },

    handleError(err) {
      DumpError("Electric Panel loggedin load error", err);
      this.loadState = "error";
    },

    focusAndReload() {
      setTimeout(() => {
        this.slatFocus(1);
      }, 1000);
      this.setLocalStorageReload();
    },

    async getData() {
      const [
        regulatoryRebateText1,
        regulatoryRebateText2,
        county,
        upgradeReasons,
        workScopeItems,
      ] =  await  Promise.all([
        getConfigValue('TXT_RebateRegulatorySharingText1', this.REGULATORY_REBATE_SHARING_TEXT1_DEFAULT, null),
        getConfigValue('TXT_RebateRegulatorySharingText2', this.REGULATORY_REBATE_SHARING_TEXT2_DEFAULT, null),
        this.lookupCounty(this.productSubmission.account.premiseAddress.city),
        GMPAPI.getElectricalSystemUpgradeReasons(),
        GMPAPI.getElectricalSystemUpgradeProjectScope(),
      ]);


      this.REGULATORY_REBATE_SHARING_TEXT1 = regulatoryRebateText1;
      this.REGULATORY_REBATE_SHARING_TEXT2 = regulatoryRebateText2;
      this.upgradeReasonItems = upgradeReasons;
      this.workScopeItems = workScopeItems;
    },

    setLocalStorageReload() {
      setTimeout(() => {
        this.resetState();
      }, this.SIX_HOURS); // flush local storage & reload page after 6 hours
    },

    addFile() {
      const docLabel = this.uploadDocumentList.length + 1;
      this.uploadDocumentList.push(
        {
          documentName: electricalQuote,
          displayName: 'Supporting Document ' + docLabel,
          error: undefined,
          file: null,
          fileName: null,
          fileSize: null,
        }
      )
    },

    capitalizeFirstLetters(str) {
      str = str.toLowerCase();
      const words = str.split(" ");
      for (let i = 0; i < words.length; i++) {
        words[i] = words[i][0].toUpperCase() + words[i].slice(1);
      }
      return words.join(" ");
    },

    async handleCountyChange(event) {
      let county = event.target.value;  
      const effectiveDate = ToServerDateTruncate(new Date());
      await this.getEICMeta(this.productSubmission.submissionType, effectiveDate, county);
      this.buildIncomeDropDown();
    },

    async lookupCounty(townName) {
      try {
        let response = await GMPAPI.GetCounty(this.capitalizeFirstLetters(townName));
        if (response.length === 1 && response[0].county != null && response[0].county != '') {
          this.productSubmission.account.premiseAddress.county = this.capitalizeFirstLetters(response[0].county);
          this.isPremiseCountyFound = true;
        }
      } catch (err) {
        this.isPremiseCountyFound = false;
      }
    },

    filterElectricians() {
      let filteredElectricians = [];
      for (let j = 0; j < this.electricians.length; j++) {
        filteredElectricians = this.electricians.filter(electrician => {
          return electrician.name.toLowerCase().startsWith(this.productSubmission.electricalWork.electrician.toLowerCase());
        })
      }
      this.filters.electrician = [];
      for (let j = 0; j < filteredElectricians.length; j++) {
        this.filters.electrician.push({
          id: j,
          name: filteredElectricians[j].name,
          electricianAccountId: filteredElectricians[j].electricianAccountId
        });
      }
    },

    setElectrician(electrician) {
      if (electrician) {
        this.productSubmission.electricalWork.electricianName = electrician.name;
        this.productSubmission.electricalWork.electricianAccountId = electrician.electricianAccountId;
        this.filters.electrician = [];
      }
    },

    selectElectrician(event) {
      const typeAhead = document.querySelector(".typeAhead-container")
      if (typeAhead) {
        if (event.keyCode === 38 && this.currentElectrician > 0) {
          this.currentElectrician--;
          typeAhead.children[this.currentElectrician].scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
        } else if (event.keyCode === 40 && this.currentElectrician < this.filters.electrician.length - 1) {
          this.currentElectrician++;
          typeAhead.children[this.currentElectrician].scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });;
        } else if (event.keyCode === 13) {
          this.productSubmission.electricalWork.electrician = this.filters.electrician[this.currentElectrician].name;
          this.hideTypeAhead();
        }
      }
    },

    handleChangeHasElectrician() {
      if (!this.hasElectrician) {
        this.productSubmission.electricalWork.electricianName = undefined;
      }
    },

    toTitleCase(str) {
      if (!str) { return str; }
      return str.replace(
        /\w\S*/g,
        (txt) => {
          return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        }
      );
    },

    gotoPage(page) {
      this.isKeyboarding = false;
      this.$router.push({ path: "/form/" + `${page}/` });
      this.slatFocus(page);
    },

    chooseAccount() {
      this.isKeyboarding = false;
      this.$router.push({ path: "/account" });
    },

    slatFocus(page) {
      // No good way to wait for the route to finish loading DOM, so do a flat timeout
      setTimeout(() => {
        var elem = document.getElementById(`eicproduct-slat-${page}`);
        elem.scrollIntoView();
      }, 100);
    },

    setSubmissionAccount() {
      this.productSubmission.account.accountNumber = this.currentAccount.accountNumber;
      this.productSubmission.account.firstName = `${this.toTitleCase(this.currentAccount.firstName)}`;
      this.productSubmission.account.lastName = `${this.toTitleCase(this.currentAccount.lastName)}`;
      this.productSubmission.account.mailingAddress = this.currentAccount.mailingAddress;
      this.productSubmission.account.premiseAddress = this.currentAccount.address;
      this.productSubmission.propertyOwner.contactPhoneNumber = this.currentAccount.phone;
      this.productSubmission.propertyOwner.confirmationEmailAddress = this.currentAccount.emailAddress;
    },

    async finishPage(currentSlatNumber) {
      localStorage.removeItem("localProductSubmission");
      this.setWithExpiry("localProductSubmission", this.productSubmission, this.SIX_HOURS);

      const validationResult = await this.$validator.validateAll('page' + currentSlatNumber);
      if (validationResult) {
        if (!this.errors) this.errors = new ErrorBag();

        switch (currentSlatNumber) {
          case "2":
            const effectiveDate = ToServerDateTruncate(new Date());
            await this.getEICMeta(this.productSubmission.submissionType, effectiveDate, this.productSubmission.account.premiseAddress.county);
            break;

          case "3":
            this.errors.clear(currentSlatNumber);
            if (!this.isSingleFamilyHome) {
              currentSlatNumber++;
            } 
            break;

          case "4":
            // Add logic for uploaded files if needed
            this.errors.clear(currentSlatNumber);
            break;

          case "5":
            this.errors.clear(currentSlatNumber);
            break;

          case "6":
            this.errors.clear(currentSlatNumber);
            break;
        }

        if (this.errors.any(currentSlatNumber)) {
          this.slatFocus(currentSlatNumber);
        } else {
          this.$store.commit("setLastValidPage", currentSlatNumber);
          this.gotoPage(++currentSlatNumber);
        }
      }
    },

    async getEICMeta(productType, incentiveDate, countyName) {
      try {
        this.loadState = undefined;
        this.metadata = await GMPAPI.GetEICMeta(productType, incentiveDate, countyName);
        this.maxFileLoadSize = this.metadata.maxFileUploadSize;
        this.loadState = 'complete';
      } catch (err) {
        this.loadState = 'error';
        DumpError("Electric Panel Metadata error", err);
      }
    },

    clearIncomeDropDown() {
      if (this.incomeAmounts.length > 0) {
        this.incomeAmounts.length = 0;
      }
    },

    buildIncomeDropDown() {
      if (!this.metadata || this.metadata.countyIncomeIncentives.length === 0) return;

      this.clearIncomeDropDown();
      this.sortCountyIncomeIncentives();
      const maxNumberInHousehold = this.getMaxNumberInHousehold();
      const entriesForMaxHousehold = this.getEntriesForMaxHousehold(maxNumberInHousehold);
      this.addEntriesForMaxHousehold(entriesForMaxHousehold, maxNumberInHousehold);

      const householdIncentives = this.getHouseholdIncentives();
      if (householdIncentives.length === 2) {
        this.setIncomeAmounts(householdIncentives);
      }
    },

    sortCountyIncomeIncentives() {
      this.metadata.countyIncomeIncentives.sort((a, b) =>
        a.numberInHousehold === b.numberInHousehold
          ? a.incomeLimit - b.incomeLimit
          : a.numberInHousehold - b.numberInHousehold
      );
    },

    getMaxNumberInHousehold() {
      return Math.max(
        ...this.metadata.countyIncomeIncentives.map(item =>
          isNaN(parseInt(item.numberInHousehold)) ? 0 : parseInt(item.numberInHousehold)
        )
      );
    },

    getEntriesForMaxHousehold(maxNumberInHousehold) {
      return this.metadata.countyIncomeIncentives.filter(
        item => parseInt(item.numberInHousehold) === maxNumberInHousehold
      );
    },

    addEntriesForMaxHousehold(entriesForMaxHousehold, maxNumberInHousehold) {
      entriesForMaxHousehold.forEach(entry => {
        this.metadata.countyIncomeIncentives.push({
          numberInHousehold: `${maxNumberInHousehold + 1} or more`,
          incomeLimit: entry.incomeLimit,
          incentiveAmount: entry.incentiveAmount,
          id: entry.id,
        });
      });
    },

    getHouseholdIncentives() {
      return this.metadata.countyIncomeIncentives.filter(
        incentive => incentive.numberInHousehold === this.productSubmission.householdInfo.numberInHousehold
      );
    },

    setIncomeAmounts(householdIncentives) {
      const [lowerIncentive, upperIncentive] = householdIncentives;

      this.incomeAmounts = [
        {
          incomeLevelId: lowerIncentive.id,
          incomeOption: `$${lowerIncentive.incomeLimit.toLocaleString()} or less`,
          incomeAmount: lowerIncentive.incomeLimit,
          incomeLevel: lowerIncentive.incomeLevel,
        },
        {
          incomeLevelId: upperIncentive.id,
          incomeOption: `$${(lowerIncentive.incomeLimit + 1).toLocaleString()} - $${upperIncentive.incomeLimit.toLocaleString()}`,
          incomeAmount: upperIncentive.incomeLimit,
          incomeLevel: upperIncentive.incomeLevel
        },
        {
          incomeLevelId: '',
          incomeOption: `Greater than $${upperIncentive.incomeLimit.toLocaleString()}`,
          incomeAmount: upperIncentive.incomeLimit + 1,
          incomeLevel: ''
        },
      ];
    },

    updateFileList(evt) {
      this.documents = evt;
    },

    closeModal(modalName) {
      if (modalName === 'fileLoadTooBig') {
        this.showFileLoadTooBigModal = false;
        this.gotoPage(4);
      }

      if (modalName === 'duplicateFilename') {
        this.showDuplicateFilenameModal = false;
        this.gotoPage(4);
      }
    },

    onSave() {
      this.showDocumentChecklist = false;
      this.Save();
    },

    async handleSubmit() {
      this.pendingSubmit = true;
      this.productSubmission.submissionSource = this.CUSTOMER;

      const formdata = new FormData();
      formdata.append('productSubmission', new Blob([JSON.stringify(this.productSubmission)], { type: "application/json" }));

      for (let i = 0; i < this.documents.length; i++) {
        formdata.append('files', this.documents[i].file);
      }

      try {
        localStorage.removeItem("localProductSubmission")
        await GMPAPI.SubmitElectricPanelGrant(this.productSubmission.account.accountNumber, formdata);
        this.$router.push({ path: "/complete" });
      } catch (err) {
        DumpError("Electric Panel Submission error", err);
        if (err.response && err.response.data) {
          let code = err.response.data.status;
          if (err.response.data.message) {
            console.error(err.response.data.message);
            code = err.response.data.code;
            this.$router.push({ path: "/error/" + code });
          } else {
            this.$router.push({ path: "/error/" + code });
          }
        } else {
          this.$router.push({ path: "/error/" });
        }
      }
    },

    setWithExpiry(key, value, ttl) {
      const now = new Date()
      // `item` is an object which contains the original value
      // as well as the time when it's supposed to expire
      const item = {
        value: value,
        expiry: now.getTime() + ttl,
      }
      localStorage.setItem(key, JSON.stringify(item));
    },

    getWithExpiry(key) {
      const itemStr = localStorage.getItem(key);
      if (!itemStr) {
        return null;
      }
      const item = JSON.parse(itemStr);
      const now = new Date();
      if (now.getTime() > item.expiry) {
        localStorage.removeItem(key);
        return null;
      }
      return item.value;
    },

    resetState() {
      localStorage.removeItem("localProductSubmission");
      window.location.reload(true);
    },
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>